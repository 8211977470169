import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import Firebase, { FirebaseContext } from './Firebase'
import Authentication from './User/Session/withAuthentication'
import { ThemeProvider } from '@material-ui/styles'
import theme from './Theme'

ReactDOM.render(
	<FirebaseContext.Provider value={new Firebase()}>
		<Authentication>
			<Router>
				<ThemeProvider theme={theme}>
					<App />
				</ThemeProvider>
			</Router>
		</Authentication>
	</FirebaseContext.Provider>,
	document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
