import React from 'react'
import { Link } from 'react-router-dom'
import Authorise from './Authorise'
import Contents from './Contents'
import Field from './Field'
import ChapterSelector from './ChapterSelector'
import Tabs from './Tabs'
import useReactRouter from './router'

import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import { red } from '@material-ui/core/colors'
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'

import GridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile'
import { ButtonBase } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
	buttonBase: {
		height: '100%'
	},
	card: {
		height: '100%',
		backgroundColor: '#242424'
	},
	media: {
		height: 0,
		paddingTop: '56.25%' // 16:9
	},
	avatar: {
		backgroundColor: red[500]
	},
	cardContent: {
		maxHeight: 100,
		overflow: 'hidden'
	},
	gridListTile: {
		width: '100% !important'
	},
	addNew: {
		marginTop: 14
	},
	chip: {
		page: {
			margin: theme.spacing(1)
		}
	}
}))

const LinkTo = React.forwardRef((props, ref) => (
	<Link innerRef={ref} {...props} />
))

export const Wargears = ({
	name,
	type,
	rarity,
	chapter,
	category,
	chapterBadge,
	contents
}) => {
	const classes = useStyles()

	return (
		<ButtonBase
			className={`${classes.buttonBase}`}
			style={{ textDecoration: 'none' }}
			focusRipple
			to={`../../${category}/${name}/`}
			component={LinkTo}
		>
			<Card className={`${classes.card}`}>
				<CardHeader
					avatar={
						<Avatar aria-label='wargear' className={classes.avatar}>
							{chapterBadge || (
								<svg
									style={{ width: '24px', height: '24px' }}
									viewBox='0 0 24 24'
								>
									<path
										fill='#000000'
										d='M6.2,2.44L18.1,14.34L20.22,12.22L21.63,13.63L19.16,16.1L22.34,19.28C22.73,19.67 22.73,20.3 22.34,20.69L21.63,21.4C21.24,21.79 20.61,21.79 20.22,21.4L17,18.23L14.56,20.7L13.15,19.29L15.27,17.17L3.37,5.27V2.44H6.2M15.89,10L20.63,5.26V2.44H17.8L13.06,7.18L15.89,10M10.94,15L8.11,12.13L5.9,14.34L3.78,12.22L2.37,13.63L4.84,16.1L1.66,19.29C1.27,19.68 1.27,20.31 1.66,20.7L2.37,21.41C2.76,21.8 3.39,21.8 3.78,21.41L7,18.23L9.44,20.7L10.85,19.29L8.73,17.17L10.94,15Z'
									/>
								</svg>
							)}
						</Avatar>
					}
					// action={
					// 	<IconButton aria-label='settings'>
					// 		<MoreVertIcon />
					// 	</IconButton>
					// }
					title={name}
					subheader={`${rarity} - ${type}`}
				/>
				<CardContent className={classes.cardContent}>
					{contents &&
						contents.map(content => (
							<Typography
								key={content.text}
								variant='body2'
								color='textSecondary'
								component='p'
							>
								{content.text}
							</Typography>
						))}
				</CardContent>
				<CardActions disableSpacing>
					<Button size='small' color='primary'>
						Read more
					</Button>
				</CardActions>
			</Card>
		</ButtonBase>
	)
}

export const WargearList = ({ cards, match }) => {
	const classes = useStyles()

	return (
		<>
			<GridList cellHeight='auto' className={classes.gridList}>
				{cards.map((wargear, i) => (
					<GridListTile
						className={`col-lg-4 col-sm-6 col-xs-12 ${
							classes.gridListTile
						}`}
						key={wargear.name}
					>
						<Wargears {...wargear} match={match} />
					</GridListTile>
				))}
			</GridList>
			<Authorise group='admin' role='admin'>
				<Button
					variant='outlined'
					to='new/'
					component={LinkTo}
					className={classes.addNew}
					color='primary'
				>
					New Wargear
				</Button>
			</Authorise>
		</>
	)
}

export const Wargear = props => {
	const {
		onSubmit,
		page,
		edit,
		onChange,
		onContentChanged,
		onChangeChapterId,
		onCancel,
		card,
		cardAlt
	} = props

	return (
		<form onSubmit={onSubmit}>
			<div className='heading'>
				{page && !edit && card.tags && card.tags.length ? (
					<div style={{ float: 'right', display: 'inline' }}>
						{card.tags.split(',').map(v => (
							<Chip
								size='small'
								variant='outlined'
								label={v.trim()}
								to={`../../search/${v.trim()}`}
								component={LinkTo}
							/>
						))}
					</div>
				) : (
					<Field
						style={{ float: 'right' }}
						title=''
						dataType='array'
						type='text'
						value={card.tags}
						valueAlt={cardAlt.tags}
						name='tags'
						onChange={onChange}
						placeholder='Tags, seporate with a comma'
						page={page}
						edit={edit}
					/>
				)}
				<Typography variant='h2'>
					{page && !edit ? (
						<>{card && card.name}</>
					) : (
						<input
							type='text'
							name='name'
							placeholder='Name'
							value={cardAlt.name}
							onChange={onChange}
						/>
					)}
				</Typography>
			</div>
			<div className='row'>
				<div className='col-md-4 order-md-2'>
					<div className='side-info'>
						<dl>
							<Field
								title='Type'
								value={card.type}
								valueAlt={cardAlt.type}
								name='type'
								onChange={onChange}
								page={page}
								edit={edit}
							/>
							<Field
								title='Rarity'
								value={card.rarity}
								valueAlt={cardAlt.rarity}
								name='rarity'
								onChange={onChange}
								page={page}
								edit={edit}
							/>
							<Field
								title='Wielder'
								name='wielder'
								value={card.wielder}
								valueAlt={cardAlt.wielder}
								onChange={onChange}
								page={page}
								edit={edit}
							/>
							<ChapterSelector
								title='Chapter'
								card={card}
								cardAlt={cardAlt}
								onChangeChapterId={onChangeChapterId}
								page={page}
								edit={edit}
							/>
						</dl>
					</div>
				</div>
				<div className='col-md-8 order-md-1'>
					<Contents
						form='contents'
						contents={card.contents}
						contentsAlt={cardAlt.contents}
						onContentChanged={onContentChanged}
						page={page}
						edit={edit}
					/>
				</div>
			</div>
			<div>
				{page && !edit ? (
					<>
						{card.rules && (
							<Tabs>
								{card.rules.map(rule => (
									<div title={rule.title}>{rule.text}</div>
								))}
							</Tabs>
						)}
					</>
				) : (
					<div className='mb-4'>
						<Contents
							form='rules'
							contents={card.rules}
							contentsAlt={cardAlt.rules}
							onContentChanged={onContentChanged}
							page={page}
							edit={edit}
						/>
					</div>
				)}
			</div>
			{page && !edit && (
				<Authorise group='admin' role='admin'>
					<Button
						color='primary'
						style={{ float: 'right' }}
						to='edit/'
						variant='outlined'
						component={LinkTo}
					>
						Edit
					</Button>
				</Authorise>
			)}
			{(edit || !page) && (
				<div>
					<input type='submit' value='Submit' name='submit' />
					<input type='button' value='Cancel' onClick={onCancel} />
				</div>
			)}
		</form>
	)
}
