import { createMuiTheme } from '@material-ui/core/styles'
import { red, blue } from '@material-ui/core/colors'

const theme = createMuiTheme({
	palette: {
		type: 'dark',
		primary: red,
		secondary: blue
	},
	status: {
		danger: 'orange'
	},
	overrides: {
		MuiTypography: {
			h2: {
				fontFamily: 'kq, sans-serif',
				fontSize: '2.5rem'
			}
		}
	}
})

export default theme
