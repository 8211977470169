import React, { useContext, useState, useEffect } from 'react'
import FirebaseContext from './Firebase/context'
import { Link } from 'react-router-dom'
import { Wargears } from './wargear'
import { Characters } from './character'

import { makeStyles } from '@material-ui/core/styles'
import GridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile'
import { ajaxArray } from './ajax'

const useStyles = makeStyles(theme => ({
	gridListTile: {
		width: '100% !important'
	}
}))

const Search = ({ match }) => {
	console.log('search 1')
	const classes = useStyles()
	const firebase = useContext(FirebaseContext)
	const [searchTerm, setSearchTerm] = useState(match.params.search || '')
	const [searchResults, setSearchResults] = useState([])
	const [loading, setLoading] = useState(false)

	const search = () => {
		setLoading(true)

		let searchTerms = searchTerm
			.split(/\s+/g)
			.map(v =>
				ajaxArray({ firebase, field: 'tags', value: v.toLowerCase() })
			)

		Promise.all(searchTerms).then(([...results]) => {
			let items = new Map() //Map removes duplicate key entries
			results.forEach(query => {
				query.forEach(doc => {
					//use the doc id as the key which removes duplicates
					items.set(doc.id, { ...doc.data(), id: doc.id })
				})
			})
			setSearchResults([...items])
			setLoading(false)
		})
	}

	const onChange = e => {
		setSearchTerm(e.target.value)
	}

	useEffect(() => {
		if (searchTerm !== '') search()
	}, [match.params.search])

	return (
		<>
			<input type='text' value={searchTerm} onChange={onChange} />
			<input
				type='button'
				value='Search'
				onClick={search}
				disabled={loading}
			/>
			<GridList cellHeight='auto' className={classes.gridList}>
				{searchResults && searchResults.length ? (
					searchResults.map(([key, item]) => (
						<GridListTile
							className={`col-lg-4 col-sm-6 col-xs-12 ${
								classes.gridListTile
							}`}
							key={key}
						>
							<Link to={`../../${item.category}/${item.name}/`}>
								{
									{
										wargear: <Wargears {...item} />,
										characters: <Characters {...item} />
									}[item.category]
								}
							</Link>
						</GridListTile>
					))
				) : (
					<div>No Search Results</div>
				)}
			</GridList>
		</>
	)
}

export default Search
