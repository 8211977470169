const deepClone = o => {
	var copy = o,
		k

	if (o && typeof o === 'object') {
		copy = Object.prototype.toString.call(o) === '[object Array]' ? [] : {}
		for (k in o) {
			copy[k] = deepClone(o[k])
		}
	}

	return copy
}

export default deepClone
