import React from 'react'
import { Link } from 'react-router-dom'
import Authorise from './Authorise'
import Contents from './Contents'
import Field from './Field'
import { ButtonBase, Card, CardHeader, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import GridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile'

const useStyles = makeStyles(theme => ({
	gridListTile: {
		width: '100% !important'
	},
	card: {
		backgroundColor: '#242424',
		width: '100%',
		display: 'grid',
		[theme.breakpoints.down('xl')]: {
			gridTemplateColumns: '2fr 10fr',
			gridTemplateRows: '3rem auto 3fr 1fr',
			gridTemplateAreas: `
    "imageWrapper chapterTitle"
    "imageWrapper info"
    "imageWrapper text"
    "imageWrapper readMore"`
		},
		[theme.breakpoints.down('sm')]: {
			gridTemplateColumns: '160px auto',
			gridTemplateRows: '1fr 1fr auto 50px',
			gridTemplateAreas: `    
    "imageWrapper chapterTitle"
    "imageWrapper info"
    "text text"
    "readMore readMore"`
		},
		[theme.breakpoints.down('xs')]: {
			gridTemplateColumns: '100%',
			gridTemplateRows: '3rem auto auto auto auto',
			gridTemplateAreas: `
    "chapterTitle"
    "imageWrapper"    
    "info"
    "text"
    "readMore"`
		}
	},
	buttonBase: {
		height: '100%',
		width: '100%'
	},
	image: {
		[theme.breakpoints.down('xl')]: {
			maxWidth: '160px',
			width: '100%'
		},
		[theme.breakpoints.down('xs')]: {
			width: '90%',
			maxWidth: '270px'
		}
	},
	imageWrapper: {
		gridArea: 'imageWrapper',
		textAlign: 'center',
		width: '100%'
	},
	info: {
		color: '#7e7e7e',
		gridArea: 'info',
		padding: '0 10px',
		[theme.breakpoints.down('xs')]: {
			padding: '10px'
		}
	},
	title: {
		fontWeight: 'bold',
		fontSize: '1.4rem',
		fontFamily: 'kq !important',
		padding: '0 10px',
		gridArea: 'chapterTitle',
		color: '#dc1535',
		[theme.breakpoints.down('xs')]: {
			textAlign: 'center'
		}
	},
	text: {
		color: '#7e7e7e',
		gridArea: 'text',
		[theme.breakpoints.down('xl')]: {
			padding: '0 10px'
		},
		[theme.breakpoints.down('sm')]: {
			padding: '10px'
		}
	},
	readMore: {
		gridArea: 'readMore',
		color: '#dc1535',
		padding: '0 10px'
	}
}))

const LinkTo = React.forwardRef((props, ref) => (
	<Link innerRef={ref} {...props} />
))

export const Chapter = props => {
	const {
		onSubmit,
		page,
		edit,
		onChange,
		onContentChanged,
		onChangeChapterId,
		onCancel,
		card,
		cardAlt
	} = props

	return (
		<form onSubmit={onSubmit}>
			<div>
				{page && !edit && (
					<Authorise group='admin' role='admin'>
						<Link style={{ float: 'right' }} to='edit/'>
							Edit
						</Link>
					</Authorise>
				)}
				<h2>
					{page && !edit ? (
						<>{card && card.name}</>
					) : (
						<input
							type='text'
							name='name'
							placeholder='Chapter Name'
							value={card.name}
							valueAlt={cardAlt.name}
							onChange={onChange}
						/>
					)}
				</h2>
				<div id='chapter-info' className='mb-4 row '>
					<div className='col-md-4' style={{ textAlign: 'center' }}>
						<img
							src={card.image}
							alt='img'
							style={{ width: '100%', maxWidth: '700px' }}
						/>
					</div>

					<div className='col-md-4'>
						<dl>
							<Field
								title='Colours'
								value={card.colours}
								valueAlt={cardAlt.colours}
								name='colours'
								onChange={onChange}
								page={page}
								edit={edit}
							/>
							<Field
								title='Battlecry'
								value={card.battlecry}
								valueAlt={cardAlt.battlecry}
								name='battlecry'
								onChange={onChange}
								page={page}
								edit={edit}
							/>
							<Field
								title='Strength'
								value={card.strength}
								valueAlt={cardAlt.strength}
								name='strength'
								onChange={onChange}
								page={page}
								edit={edit}
							/>
							<Field
								title='Chapter Master'
								value={card.chaptermaster}
								valueAlt={cardAlt.chaptermaster}
								name='chaptermaster'
								onChange={onChange}
								page={page}
								edit={edit}
							/>
							<Field
								title='Homeworld'
								value={card.homeworld}
								valueAlt={cardAlt.homeworld}
								name='homeworld'
								onChange={onChange}
								page={page}
								edit={edit}
							/>
						</dl>
					</div>
					<div className='col-md-4'>
						<div
							style={{
								height: '150px',
								width: '150px',
								border: '1px solid #3a3a3a',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center'
							}}
						>
							Chapter Symbol
						</div>
					</div>
				</div>
				<div>
					<Contents
						form='contents'
						contents={card.contents}
						contentsAlt={cardAlt.contents}
						page={page}
						edit={edit}
						onContentChanged={onContentChanged}
					/>
				</div>
				{(edit || !page) && (
					<div>
						<input type='submit' value='Submit' name='submit' />
						<input type='button' value='Cancel' onClick={onCancel} />
					</div>
				)}
			</div>
		</form>
	)
}

const Cards = props => {
	const classes = useStyles()

	return (
		<ButtonBase
			className={`${classes.buttonBase}`}
			style={{ textDecoration: 'none' }}
			focusRipple
			to={`../../${props.category}/${props.name}/`}
			component={LinkTo}
		>
			<Card className={`${classes.card}`}>
				<div className={classes.imageWrapper}>
					<img className={classes.image} src={props.image} />
				</div>
				<CardHeader
					title={props.name}
					className={classes.title}
				></CardHeader>
				<div className={classes.info}>
					<i>
						<span>Colours: {props.colours}</span>
						<span className='pipe'> | </span>
						<span>Battlecry: {props.battlecry}</span>
						<span className='pipe'> | </span>
						<span>Strength: {props.strength}</span>
					</i>
				</div>
				<div className={`${classes.text}`}>
					<Typography component='p'>{props.summary}</Typography>
				</div>
				<div className={classes.readMore}>
					<span>Read more...</span>
				</div>
			</Card>
		</ButtonBase>
	)
}

export const ChapterList = props => {
	const { cards } = props
	const classes = useStyles()
	return (
		<GridList cellHeight='auto' className={classes.gridList}>
			{cards.map((card, i) => (
				<GridListTile className={classes.gridListTile} key={card.name}>
					<Cards {...card} match={props.match} key={i} />
				</GridListTile>
			))}
		</GridList>
	)
}
