import React, { useContext, useEffect, useState } from 'react'
import AuthUserContext from './context'
import { FirebaseContext } from '../../Firebase'

const Authentication = props => {
	const [authUser, setAuthUser] = useState({})
	const firebase = useContext(FirebaseContext)
	let listener = undefined

	useEffect(() => {
		listener = firebase.onAuthUserListener(
			authUser => {
				setAuthUser(authUser)
			},
			() => {
				setAuthUser(null)
			}
		)
		return () => listener()
	}, [])

	return (
		<AuthUserContext.Provider value={authUser}>
			{props.children}
		</AuthUserContext.Provider>
	)
}

export default Authentication
