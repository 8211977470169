import React, { useContext } from 'react'
import { FirebaseContext } from '../../Firebase'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const SignOutButton = ({ classes = {}, children }) => {
	const firebase = useContext(FirebaseContext)

	return (
		<Button
			className={classes.navLinks}
			onClick={firebase.doSignOut}
			component='a'
		>
			{children || 'Sign Out'}
		</Button>
	)
}

export default SignOutButton
