import React from 'react'
import { Link } from 'react-router-dom'

export const Footer = () => {
	return (
		<div id='foot'>
			<Link to={`/page/Disclaimer/`}>Disclaimer</Link>
		</div>
	)
}

export default Footer
