import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { FirebaseContext } from '../../Firebase'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'

const useStyles = makeStyles(theme => ({
	button: {
		margin: theme.spacing(1)
	}
}))

// import { withFirebase } from '../Firebase'
// import * as ROUTES from '../../constants/routes'

const PasswordForgetPage = () => (
	<div>
		<h1>Forgot Password</h1>
		<PasswordForgetForm />
	</div>
)

const INITIAL_STATE = {
	email: '',
	error: null
}

const PasswordForgetFormBase = props => {
	const firebase = useContext(FirebaseContext)
	const [state, setState] = useState({})
	const classes = useStyles()

	const onSubmit = event => {
		const { email } = state

		firebase
			.doPasswordReset(email)
			.then(() => {
				setState({ ...INITIAL_STATE })
			})
			.catch(error => {
				setState({ error })
			})

		event.preventDefault()
	}

	const handleChange = name => event => {
		setState({ [name]: event.target.value })
	}

	const { email, error } = state
	const isInvalid = email === ''

	return (
		<form onSubmit={onSubmit}>
			<TextField
				className={classes.button}
				label='Email'
				name='email'
				value={email}
				onChange={handleChange('email')}
				type='text'
			/>
			<br />
			<Button
				className={classes.button}
				disabled={isInvalid}
				variant='outlined'
				color='primary'
				type='submit'
			>
				Reset Password
			</Button>

			{error && <p>{error.message}</p>}
		</form>
	)
}

const PasswordForgetLink = () => (
	<Link to='../forgotpassword/'>Forgot Password?</Link>
)

export default PasswordForgetPage

const PasswordForgetForm = PasswordForgetFormBase

export { PasswordForgetForm, PasswordForgetLink }
