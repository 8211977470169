import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import TabHeaders from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

export function TabPanel(props) {
	const { children, value, index, ...other } = props

	return (
		<Typography
			component='div'
			role='tabpanel'
			hidden={value !== index}
			id={`scrollable-auto-tabpanel-${index}`}
			aria-labelledby={`scrollable-auto-tab-${index}`}
			{...other}
		>
			<Box p={3}>{children}</Box>
		</Typography>
	)
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired
}

function switchTab(index) {
	return {
		id: `scrollable-auto-tab-${index}`,
		'aria-controls': `scrollable-auto-tabpanel-${index}`
	}
}

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		width: '100%',
		backgroundColor: theme.palette.background.paper
	}
}))

export default function Tabs({ children }) {
	const classes = useStyles()
	const [value, setValue] = React.useState(0)

	function handleChange(event, newValue) {
		setValue(newValue)
	}

	function displayContents() {
		let contents = []
		React.Children.map(children, (child, i) => {
			contents.push(
				<TabPanel value={value} index={i}>
					{child}
				</TabPanel>
			)
		})
		return contents
	}

	function displayTabs() {
		let tabs = []
		React.Children.map(children, (child, i) => {
			tabs.push(<Tab label={child.props.title} {...switchTab(i)} />)
		})
		return tabs
	}

	return (
		<div className={classes.root}>
			<AppBar position='static' color='default'>
				<TabHeaders
					value={value}
					onChange={handleChange}
					indicatorColor='primary'
					textColor='primary'
					variant='scrollable'
					scrollButtons='auto'
					aria-label='scrollable auto tabs example'
				>
					{displayTabs()}
				</TabHeaders>
			</AppBar>
			{displayContents()}
		</div>
	)
}

// My own simple implementation, no dependancies other than React.
// <Tabs>
//    <Tab title="title">Content</Tab>
// </Tabs>

// import React, { useState } from 'react'

// export const Tab = ({ i, isActive, selectTab, children }) => {
// 	return (
// 		<div
// 			className={isActive ? 'active' : undefined}
// 			style={{ display: isActive ? 'block' : 'none' }}
// 		>
// 			{children}
// 		</div>
// 	)
// }

// export const Tabs = props => {
// 	const [activeIndex, setActiveIndex] = useState(0)

// 	let contents = []

// 	return (
// 		<div className='rules tabs' {...props}>
// 			<ul className='tablink d-none d-md-block' style={{ paddingLeft: 0 }}>
// 				{React.Children.map(props.children, (child, i) => {
// 					const isActive = i === activeIndex

// 					//Accordion headers
// 					contents.push(
// 						React.createElement(
// 							'div',
// 							{
// 								className: 'd-md-none',
// 								onClick: e => {
// 									e.preventDefault()
// 									setActiveIndex(i)
// 								}
// 							},
// 							<a href='#'>{child.props.title}</a>
// 						)
// 					)

// 					//Gathers the contents
// 					contents.push(
// 						React.cloneElement(child, {
// 							key: i,
// 							i,
// 							isActive
// 						})
// 					)

// 					//returns the title attr as the tabcordion header.
// 					return typeof child.props.title === 'function' ? (
// 						React.cloneElement(child.props.title(), {
// 							onClick: () => setActiveIndex(i),
// 							className: isActive
// 								? 'active ' + child.props.title().props.className
// 								: child.props.title().props.className
// 						})
// 					) : (
// 						<li
// 							className={isActive ? 'active' : undefined}
// 							style={{
// 								display: 'inline'
// 							}}
// 							onClick={() => setActiveIndex(i)}
// 						>
// 							{child.props.title}
// 						</li>
// 					)
// 				})}
// 			</ul>
// 			<div className='tabtext'>{contents}</div>
// 		</div>
// 	)
// }

// export default Tabs
