import React, { Component, useContext, useState, useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { FirebaseContext } from '../../Firebase'
import { stat } from 'fs'

const SignUpPage = () => (
	<div>
		<h1>SignUp</h1>
		<SignUpForm />
	</div>
)

const INITIAL_STATE = {
	username: '',
	email: '',
	passwordOne: '',
	passwordTwo: '',
	isAdmin: false,
	error: null
}

const SignUpFormBase = props => {
	const [state, setState] = useState({ ...INITIAL_STATE })
	const firebase = useContext(FirebaseContext)

	const onSubmit = event => {
		const { username, email, passwordOne, isAdmin } = state
		const roles = { group: 'member' }

		firebase
			.doCreateUserWithEmailAndPassword(email, passwordOne)
			.then(authUser => {
				return firebase
					.user(authUser.user.uid)
					.set(
						{
							username,
							roles
						},
						{ merge: true }
					)
					.then(() => {
						firebase
							.user(authUser.user.uid)
							.collection('private')
							.doc()
							.set({
								email
							})
					})
			})
			.then(() => {
				return firebase.doSendEmailVerification()
			})
			.then(() => {
				setState({ ...INITIAL_STATE })
				props.history.push(`/`)
			})
			.catch(error => {
				setState({ error })
			})

		event.preventDefault()
	}

	const onChange = event => {
		setState({ ...state, [event.target.name]: event.target.value })
	}

	const onChangeCheckbox = event => {
		setState({
			...state,
			[event.target.name]: event.target.checked
		})
	}

	const { username, email, passwordOne, passwordTwo, error, isAdmin } = state

	const isInvalid =
		passwordOne !== passwordTwo ||
		passwordOne === '' ||
		email === '' ||
		username === ''

	return (
		<form onSubmit={onSubmit}>
			<input
				name='username'
				value={username}
				onChange={onChange}
				type='text'
				placeholder='Full Name'
			/>
			<input
				name='email'
				value={email}
				onChange={onChange}
				type='text'
				placeholder='Email Address'
			/>
			<input
				name='passwordOne'
				value={passwordOne}
				onChange={onChange}
				type='password'
				placeholder='Password'
			/>
			<input
				name='passwordTwo'
				value={passwordTwo}
				onChange={onChange}
				type='password'
				placeholder='Confirm Password'
			/>
			<button disabled={isInvalid} type='submit'>
				Sign Up
			</button>

			{error && <p>{error.message}</p>}
		</form>
	)
}

const SignUpLink = () => (
	<p>
		Don't have an account? <Link to='/signup'>Sign Up</Link>
	</p>
)

const SignUpForm = withRouter(SignUpFormBase)

export default SignUpPage

export { SignUpForm, SignUpLink }
