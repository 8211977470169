import React, { useContext, useEffect, useState } from 'react'
import { FirebaseContext } from '../src/Firebase'
import AuthUserContext from './User/Session/context'
import useReactRouter from './router'

const categorise = firebase => {
	firebase
		.wargear()
		.get()
		.then(docs => {
			docs.forEach(doc => {
				let docData = { ...doc.data(), draft: false }
				firebase.db.doc(doc.ref.path).set(docData)

				// firebase.db
				// 	.doc(doc.ref.path)
				// 	.collection('contents')
				// 	.get()
				// 	.then(contents => {
				// 		let body = {}
				// 		contents.forEach(content => {
				// 			body = content.data()
				// 		})
				// 		return { ...characters, ...body }
				// 	})
				// 	.then(data => {
				// 		firebase.db.doc(doc.ref.path).set(data)
				// 	})
			})
		})
}

const Dashboard = () => {
	const firebase = useContext(FirebaseContext)
	return (
		<div>
			<input
				type='button'
				value='Go!'
				onClick={() => categorise(firebase)}
			/>
		</div>
	)
}

const Admin = () => {
	const firebase = useContext(FirebaseContext)
	const authUser = useContext(AuthUserContext)
	const { history } = useReactRouter()

	useEffect(() => {}, [authUser])

	if (!authUser || !authUser.uid) {
		return <div>Loading...</div>
	} else if (
		!authUser.roles ||
		!authUser.roles.admin ||
		!authUser.roles.admin === 'admin'
	) {
		//history.replace(`/signin`)
	} else {
		return <Dashboard />
	}
}

export default Admin
