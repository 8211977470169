import React, { useContext, useState, useEffect } from 'react'
import useReactRouter from './router'

import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import InputBase from '@material-ui/core/InputBase'
import SearchIcon from '@material-ui/icons/Search'
import IconButton from '@material-ui/core/IconButton'

const useStyles = makeStyles(theme => ({
	search: {
		padding: '2px 4px',
		display: 'flex',
		alignItems: 'center',
		width: 300
	},
	input: {
		marginLeft: 8,
		flex: 1
	},
	iconButton: {
		padding: 10
	}
}))

const SearchBar = ({ searchTerm, setSearchTerm, onSearch, ...props }) => {
	const classes = useStyles()

	return (
		<form onSubmit={onSearch} {...props}>
			<Paper className={classes.search}>
				<InputBase
					className={classes.input}
					placeholder='Search'
					value={searchTerm}
					onChange={e => setSearchTerm(e.target.value)}
					inputProps={{ 'aria-label': 'search google maps' }}
				/>
				<IconButton
					className={classes.iconButton}
					onClick={onSearch}
					aria-label='search'
				>
					<SearchIcon />
				</IconButton>
			</Paper>
		</form>
	)
}

export default SearchBar
