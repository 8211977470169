import React, { useEffect, useContext, useState } from 'react'
import { Route, Link } from 'react-router-dom'
import { FirebaseContext } from './Firebase'
import { ajaxList } from './ajax'

const ChapterSelector = props => {
	const { title, card, cardAlt, onChangeChapterId, page, edit } = props
	const firebase = useContext(FirebaseContext)
	const [chapterList, setChapterList] = useState([])

	useEffect(() => {
		ajaxList({
			page: 'chapter',
			firebase,
			order: ['id', 'asc'],
			cacheAs: 'chapterList'
		}).then(data => setChapterList(data))
	}, [firebase])

	return (
		<>
			<dt> {title}: </dt>
			{page && !edit ? (
				<dd>
					<Link to={`../../chapters/${card.chapter}/`}>
						{card.chapter}
					</Link>
				</dd>
			) : (
				<dd>
					<select
						name='chapterId'
						onChange={e =>
							onChangeChapterId(e, chapterList[e.target.value].name)
						}
						value={cardAlt.chapterId}
					>
						<option value='-1'>Unknown</option>
						{chapterList.map(v => (
							<option key={v.id} value={v.id}>
								{v.name}
							</option>
						))}
					</select>
				</dd>
			)}
		</>
	)
}

export default ChapterSelector
