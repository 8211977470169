import app from 'firebase'
import 'firebase/auth'
import 'firebase/firestore'

const firebaseConfig = {
	apiKey: 'AIzaSyC2jfz6KiSqIMVorGopq7dipZzcRSpaQFA',
	authDomain: 'sanguinius.firebaseapp.com',
	databaseURL: 'https://sanguinius.firebaseio.com',
	projectId: 'sanguinius',
	storageBucket: 'sanguinius.appspot.com',
	messagingSenderId: '8905483365',
	appId: '1:8905483365:web:a615c2259435c191'
}

class Firebase {
	constructor() {
		app.initializeApp(firebaseConfig)
		this.auth = app.auth()
		this.db = app.firestore()
		this.googleProvider = new app.auth.GoogleAuthProvider()
		this.fieldValue = app.firestore.FieldValue
	}

	// *** Auth API ***

	doCreateUserWithEmailAndPassword = (email, password) =>
		this.auth.createUserWithEmailAndPassword(email, password)

	doSignInWithEmailAndPassword = (email, password) =>
		this.auth.signInWithEmailAndPassword(email, password)

	doSendEmailVerification = () =>
		this.auth.currentUser.sendEmailVerification({
			url: 'http://localhost:3000'
		})

	doSignInWithGoogle = () => this.auth.signInWithPopup(this.googleProvider)

	doSignOut = () => {
		console.log('sign out')
		this.auth.signOut()
	}

	doPasswordReset = email => this.auth.sendPasswordResetEmail(email)

	doPasswordUpdate = password => this.auth.currentUser.updatePassword(password)

	// *** Merge Auth and DB User API *** //

	onAuthUserListener = (next, fallback) => {
		return this.auth.onAuthStateChanged(authUser => {
			if (authUser) {
				this.user(authUser.uid)
					.get()
					.then(snapshot => {
						const dbUser = snapshot.data()

						// default empty roles
						if (dbUser && !dbUser.roles) {
							dbUser.roles = {}
						}

						// merge auth and db user
						authUser = {
							uid: authUser.uid,
							email: authUser.email,
							emailVerified: authUser.emailVerified,
							providerData: authUser.providerData,
							...dbUser
						}

						next(authUser)
					})
			} else {
				fallback()
			}
		})
	}

	user = id => (id ? this.db.doc(`users/${id}`) : this.db.collection(`users`))

	chapters = id =>
		id
			? this.db.doc(`pages/${id}`)
			: this.db.collection(`pages`).where('category', '==', 'chapters')

	wargear = id =>
		id
			? this.db.doc(`pages/${id}`)
			: this.db.collection('pages').where('category', '==', 'wargear')

	characters = id =>
		id
			? this.db.doc(`pages/${id}`)
			: this.db.collection('pages').where('category', '==', 'characters')

	page = id => (id ? this.db.doc(`pages/${id}`) : this.db.collection('pages'))
}

export default Firebase
