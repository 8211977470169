import React, { Component, useState, useEffect, useContext } from 'react'
import { withRouter } from 'react-router-dom'

import { SignUpLink } from '../SignUp'
import { PasswordForgetLink } from '../PasswordForget'
// import * as ROUTES from '../../constants/routes'
import { FirebaseContext } from '../../Firebase'

import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'
import SendIcon from '@material-ui/icons/Send'

const useStyles = makeStyles(theme => ({
	button: {
		margin: theme.spacing(1)
	},
	icon: {
		marginLeft: theme.spacing(1)
	},
	rightIcon: {
		marginLeft: theme.spacing(1)
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: 200
	}
}))

const SignInPage = () => (
	<div>
		<h1>Sign In</h1>
		<SignInForm />
		{
			//<SignUpLink />
		}
	</div>
)

const INITIAL_STATE = {
	email: '',
	password: '',
	error: null
}

const SignInFormBase = props => {
	const firebase = useContext(FirebaseContext)
	const [state, setState] = useState({ ...INITIAL_STATE })
	const classes = useStyles()

	const onSubmit = event => {
		const { email, password } = state

		firebase
			.doSignInWithEmailAndPassword(email, password)
			.then(() => {
				setState({ ...INITIAL_STATE })
				props.history.push(`/`)
			})
			.catch(error => {
				setState({ error })
			})

		event.preventDefault()
	}

	const handleChange = name => e => {
		setState({ ...state, [name]: e.target.value })
	}

	const { email, password, error } = state

	const isInvalid = password === '' || email === ''

	return (
		<form onSubmit={onSubmit}>
			<TextField
				className={classes.textField}
				label='Email'
				name='email'
				value={email}
				onChange={handleChange('email')}
				type='text'
			/>
			<TextField
				className={classes.textField}
				label='Password'
				name='password'
				value={password}
				onChange={handleChange('password')}
				type='password'
			/>
			<br />
			<Button
				disabled={isInvalid}
				className={classes.button}
				color='primary'
				variant='contained'
				type='submit'
			>
				Sign In
				<SendIcon className={classes.icon}></SendIcon>
			</Button>
			<PasswordForgetLink />

			{error && <p>{error.message}</p>}
		</form>
	)
}

const SignInGoogleBase = props => {
	const firebase = useContext(FirebaseContext)
	const [state, setState] = useState({ error: null })
	const { error } = state

	const onSubmit = event => {
		firebase
			.doSignInWithGoogle()
			.then(socialAuthUser => {
				// Create a user in your Firebase Realtime Database too
				return firebase.user(socialAuthUser.user.uid).set(
					{
						username: socialAuthUser.user.displayName,
						email: socialAuthUser.user.email,
						roles: {}
					},
					{ merge: true }
				)
			})
			.then(() => {
				setState({ error: null })
				props.history.push(`/`)
			})
			.catch(error => {
				setState({ error })
			})

		event.preventDefault()
	}

	return (
		<form onSubmit={onSubmit}>
			<button type='submit'>Sign In with Google</button>

			{error && <p>{error.message}</p>}
		</form>
	)
}

const SignInForm = withRouter(SignInFormBase)

const SignInGoogle = withRouter(SignInGoogleBase)

export default SignInPage

export { SignInForm, SignInGoogle }
