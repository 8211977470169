import React, { useEffect } from 'react'
import './bootstrap.css'
import './sang.css'
import Navigation from './navigation'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Page from './Page'
import SignInPage from './User/SignIn'
import SignUpPage from './User/SignUp'
import Header from './Header'
import Footer from './Footer'
import useReactRouter from './router'
import Admin from './Admin'
import PasswordForgetPage from './User/PasswordForget'
import Account from './User/Account'
import Search from './Search'

const App = () => {
	const { location, history } = useReactRouter()
	if (location.pathname[location.pathname.length - 1] != '/')
		history.replace(location.pathname + '/')

	return (
		<div id='content' className='App'>
			<Navigation />
			<Header />
			<div id='main'>
				<Switch>
					<Route path='/signin/' component={SignInPage} />
					{/* <Route path='/signup/' component={SignUpPage} /> */}
					<Route path='/forgotpassword/' component={PasswordForgetPage} />
					<Route path='/account' component={Account} />
					<Route path='/admin' component={Admin} />
					<Route exact path='/search' component={Search} />
					<Route path='/search/:search' component={Search} />
					<Route exact path='/' component={Page} />
					<Route exact path='/:category/' component={Page} />
					<Route exact path='/:category/:page/' component={Page} />
					<Route exact path='/:category/:page/:edit/' component={Page} />
				</Switch>
			</div>
			<Footer />
		</div>
	)
}

export default App
