import React from 'react'

const Field = props => {
	const {
		title,
		placeholder = title,
		name,
		type = 'text',
		dataType,
		onChange,
		page,
		edit,
		value,
		valueAlt,
		style
	} = props
	return (
		<>
			<dt>{title && `${title}:`}</dt>
			{page && !edit ? (
				<dd style={style}>{value || <i>Unknown</i>}</dd>
			) : (
				{
					text: (
						<input
							type={type}
							name={name}
							style={style}
							placeholder={placeholder}
							value={valueAlt}
							onChange={onChange}
						/>
					),
					checkbox: (
						<input
							type={type}
							name={name}
							style={style}
							placeholder={placeholder}
							checked={valueAlt}
							onChange={onChange}
						/>
					)
				}[type]
			)}
		</>
	)
}

export default Field
