import React from 'react'
import { Link } from 'react-router-dom'

export const Header = () => {
   return (
      <div id='head'>
         <Link to={`/`}>Sanguinius</Link>
         <div className='site-info'>Home of Blood Angel successor chapters</div>
      </div>
   )
}

export default Header
