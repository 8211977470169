import React, { useState, useContext, useEffect } from 'react'
import { FirebaseContext } from '../../Firebase'
import AuthUserContext from '../Session/context'
import ajax from '../../ajax'

const Account = () => {
	const firebase = useContext(FirebaseContext)
	const authUser = useContext(AuthUserContext)
	const [user, setUser] = useState({})

	useEffect(() => {
		if (authUser.username)
			ajax({
				page: 'user',
				field: 'username',
				id: authUser.username,
				firebase
			}).then(data => setUser(data))
	}, [authUser])

	return (
		<div>
			{user.username && (
				<div>
					<h2>{user.username}</h2>
				</div>
			)}
		</div>
	)
}

export default Account
